<template>
  <div class="d-flex justify-content-center">
    <div class="invoice-parent">
      <div class="d-flex justify-content-center parent-container animate__animated animate__fadeIn">

        <div class="d-flex flex-column">
          <div class="intro-container my-container-light p-3">

            <h5 class="claim-title" tabindex="0">Calculadora de deuda</h5>
            <p class="claim-text ">

              Calcula de manera automática los intereses de demora generados por cada factura y escoge si quieres añadir el principal o no.
              <br>
              Pulsa Finalizar para enviarnos toda la información e iniciar el estudio de la reclamación.

            </p>

            <div class="sim-calculator-parent d-flex p-2 animate__animated animate__fadeIn" v-if="debt">

              <b-icon class="big-reclamatec-icon" icon="calculator"></b-icon>


              <table class="table simtable remove-table-hover">

                <caption class="text-center">
                  Si algún valor te parece incorrecto, revisa las facturas
                  <a href="#" @click.prevent="onReturnClick">aquí</a>
                </caption>

                <thead style="border-color: white !important;">
                <tr>
                  <th class="header-title"></th>
                  <th></th>
                  <th></th>
                  <th>Detalle de la Deuda</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-align-right"><b>Número de facturas</b></td>
                  <td></td>
                  <td></td>
                  <td>{{invoicesConfirmed && debt.invoiceCount}}</td>
                </tr>

                <tr>
                  <td class="text-align-right"><b>Total Dias</b></td>
                  <td></td>
                  <td></td>
                  <td>{{invoicesConfirmed && debt.days}}</td>
                </tr>

                <tr>
                  <td class="text-align-right"><b>Deuda por facturas impagadas</b></td>
                  <td></td>
                  <td></td>
                  <td>{{invoicesConfirmed && formarCurrencyNumber(debt.invoiceTotals)}}</td>
                </tr>
                <tr>
                  <td class="text-align-right"><b>Interés medio</b></td>
                  <td></td>
                  <td></td>
                  <td>{{invoicesConfirmed && formarPctNumber(debt.meanInterest)}}</td>
                </tr>

                <tr class="total-divider">
                  <td class="text-align-right"><b>Deuda por intereses</b></td>
                  <td></td>
                  <td></td>
                  <td>{{invoicesConfirmed && formarCurrencyNumber(debt.invoiceInterests)}}</td>
                </tr>

                </tbody>
                <tfoot>
                <tr >
                  <td class="text-align-right"><div><b>TOTAL</b></div></td>

                  <td colspan="4" class="text-align-right"><div><span style="font-size: 18px;">{{invoicesConfirmed && formarCurrencyNumber(debt.total)}}</span></div></td>
                </tr>
                </tfoot>
              </table>


            </div>


          </div>
        </div>

      </div>


      <div class="form-container invoices-list pt-1">

        <Message v-if="simError" severity="error" :closable="false">Error en el cálculo de intereses: {{simError}}</Message>

        <Message v-if="!invoicesConfirmed" severity="error" :closable="false">
          Tienes facturas sin confirmar. Para calcular la dedua es necesario confirmar todas las facturas  <a href="#" @click.prevent="onReturnClick">aquí</a>
        </Message>

        <Message v-if="!invoices.length" severity="info" :closable="false">Ninguna factura importada</Message>


        <template v-if="invoicesConfirmed && invoices.length">

          <div class="d-flex justify-content-end">

            <div class="d-flex">

              <span class="mr-2 my-font">Seleccionar totales factura: </span>
              <b-form-checkbox
                  id="totals-select"
                  @change="onTotalsSwitchClick()"
                  v-model="totalsSwitchState"
                  switch>
              </b-form-checkbox>


              <b-tooltip
                  :show.sync="showTotalTooltip"
                  variant="primary"
                  placement="topright"
                  target="totals-select">
                Puedes añadir el total de la factura seleccionando aquí
              </b-tooltip>


            </div>

          </div>

          <invoice-list
              @onEdit="onEditClick"
              @onDelete="deleteInvoice"
              :invoices="invoices">

            <template v-slot:footer="slotProps" >

              <template v-if="slotProps.invoice._ext && slotProps.invoice._ext.sim">

                <template v-if="slotProps.invoice._ext.sim.err">
                  <div class="my-sim-error">  <b-icon icon="exclamation-circle-fill" class="font-weight-bold"></b-icon> {{ellipsis(slotProps.invoice._ext.sim.err,70)}} </div>
                </template>

                <template v-else>

                  <div class="d-flex invoice-totals justify-content-between">

                    <div class="days-label">
                      <b-badge variant="primary" class="my-corporate-badge">  {{slotProps.invoice._ext.sim.days}} días</b-badge>
                    </div>

                    <!--                  <b-button v-b-toggle="`collapse-${slotProps.invoice._id}`" class="m-1">Toggle Collapse</b-button>-->

                    <div class="d-flex">

                      <div class="d-flex justify-content-end animate__animated animate__bounceIn mr-1" >
                        <div class="mr-1">
                          <span class="pt-1 mb-0"><u>Intereses</u>:</span>
                        </div>
                        <span class="border sim-currency mr-2" :class="{
                  'sim-total-label-true': slotProps.invoice._ext.intChecked,
                  'sim-total-label-false': !slotProps.invoice._ext.intChecked
                }">
                {{formarCurrencyNumber(slotProps.invoice._ext.sim.interest)}}</span>


                        <b-form-checkbox
                            @change="onInterestClick(slotProps.invoice)"
                            v-model="slotProps.invoice._ext.intChecked"
                            switch>
                        </b-form-checkbox>

                        <!--                    <div class="my-switch-parent">-->

                        <!--                      <InputSwitch-->
                        <!--                          class="my-small-input-switch"-->
                        <!--                          v-model="slotProps.invoice._ext.intChecked"-->
                        <!--                          @change="onInterestClick(slotProps.invoice)"-->
                        <!--                      />-->

                        <!--                    </div>-->

                      </div>


                      <div class="d-flex justify-content-end animate__animated animate__bounceIn" >
                        <div class="mr-1">
                          <span class="pt-1 mb-0"><u>Total</u>:</span>
                        </div>
                        <span class="border sim-currency mr-2" :class="{
                  'sim-total-label-true': slotProps.invoice._ext.totalChecked,
                  'sim-total-label-false': !slotProps.invoice._ext.totalChecked
                }">{{formarCurrencyNumber(slotProps.invoice._ext.sim.total)}}</span>



                        <b-form-checkbox
                            @change="onInterestClick(slotProps.invoice)"
                            v-model="slotProps.invoice._ext.totalChecked"
                            switch>
                        </b-form-checkbox>


                        <!--                    <div class="my-switch-parent">-->

                        <!--                      <InputSwitch-->
                        <!--                          class="my-small-input-switch "-->
                        <!--                          :id="`sim-footer-item-${slotProps.invoice._id}`"-->
                        <!--                          v-model="slotProps.invoice._ext.totalChecked"-->
                        <!--                          @change="onInterestClick(slotProps.invoice)"-->
                        <!--                      />-->

                        <!--                    </div>-->


                      </div>



                    </div>


                  </div>

                  <!--                <b-collapse :id="`collapse-${slotProps.invoice._id}`">-->
                  <!--                </b-collapse>-->

                  <table v-if="slotProps.invoice._ext.sim.periods && slotProps.invoice._ext.sim.periods.length" class=" text-center m-1 table simtable remove-table-hover">

                    <thead style="border-color: white !important;">
                    <tr class="small-header">
                      <th class="small-header" style="">Desde</th>
                      <th class="small-header">Hasta</th>
                      <th class="small-header">Días</th>
                      <th class="small-header">Base</th>
                      <th class="small-header">% Interés</th>
                      <th class="small-header">Total Interés</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        :key="`sim-period-${slotProps.invoice._id}-${k}`" v-for="(period, k) in slotProps.invoice._ext.sim.periods">
                      <td>{{period.from}}</td>
                      <td>{{period.to}}</td>
                      <td>{{period.days}}</td>
                      <td>{{formarCurrencyNumber(period.base)}}</td>
                      <td>{{period.interestPct}}%</td>
                      <td>{{formarCurrencyNumber(period.interestValue)}}</td>

                    </tr>
                    </tbody>

                  </table>




                </template>

              </template>


            </template>

          </invoice-list>


        </template>



      </div>




      <div class="d-flex mx-2 justify-content-end my-3">

        <Button id="btn-invoice-field-return"  @click="onReturnClick"
                class="vf-btn vf-btn-sm mr-1">
          <b-icon icon="skip-backward" class="mr-1" style="padding-top: 2px"></b-icon>volver
        </Button>

        <Button  id="sim-step-next-btn"  @click="onNextClick"
                 class="vf-btn vf-btn-primary vf-btn-sm"
                 style="width: 120px">finalizar
        </Button>

        <ConfirmPopup></ConfirmPopup>

        <b-tooltip v-if="debt && !debt.total" variant="danger" placement="topright" target="sim-step-next-btn" triggers="hover">
           No hay deuda acumulada
        </b-tooltip>


      </div>



    </div>



  </div>

</template>

<script>

import { mapGetters } from 'vuex';
import InvoiceList from '@/components/invoiceList';
import _ from 'lodash'
import ApiService from "@/services/api.service";
import {
  listToDict,
  logInfo,
  ellipsis,
  formarCurrencyNumber,
  formarPctNumber,
  roundDecimal,
  checkStatusConfirmed,
} from "@/helpers";
import { GO_TO_STEP, STEPS_ENUM, SAVE_SIMULATION } from '@/store/actions.type'
import ConfirmPopup from "primevue/confirmpopup";
import InputSwitch from '@/components/primevue/InputSwitch';

export default {
  name: 'simulation',
  components: {
    InvoiceList,
    ConfirmPopup,
    InputSwitch
  },
  data: () => ({
    debt: null,
    showTotalTooltip: false,
    simError: null,
    invoices: [],
    totalsSwitchState: false
  }),

  watch: {
  },


  computed: {
    ...mapGetters(['claim','lastStep']),

    invoicesConfirmed() {
      return this.invoices.reduce((prev,el)=>{
        prev &= checkStatusConfirmed(el['status'])
        return prev
      }, true)
    },



  },
  methods: {

    ellipsis,

    formarCurrencyNumber,

    formarPctNumber,

    getDefaultDeptValues() {
      return {
        days: 0,
        invoiceInterests: 0,
        invoiceTotals: 0,
        meanInterest: 0,
        total: 0,
        invoiceCount: 0,
      }
    },

    calculateDept() {
      const debt = this.getDefaultDeptValues()
      let totalCount = 0
      let intCount = 0
      let intPctSum = 0
      let intPctDays = 0

      for (const invoice of this.invoices) {
        debt.days += _.get(invoice, '_ext.sim.days',0)
        const inter = _.get(invoice, '_ext.sim.interest',0)
        const total = _.get(invoice, '_ext.sim.total',0)
        const intChecked = _.get(invoice, '_ext.intChecked',false)
        const totalChecked = _.get(invoice, '_ext.totalChecked',false)


        for(const p of _.get(invoice, '_ext.sim.periods',[])) {

          if(p.interestPct) {
            intPctSum += p.interestPct * p.days
            intPctDays += p.days
          }

        }


        totalCount += total
        intCount += inter

        if(intChecked) {
          debt.invoiceInterests += inter
          debt.total += inter
        }
        if(totalChecked) {
          debt.invoiceTotals += total
          debt.total += total
        }
      }
      debt.invoiceCount = this.invoices.length
      debt.meanInterest = roundDecimal(intPctSum / (intPctDays * 100), 4)

      this.debt = debt
    },

    async onReturnClick() {
      await this.$store.dispatch(GO_TO_STEP, STEPS_ENUM.INVOICES)
    },

    async confirm(message, target, cb) {
      this.$confirm.require({
        target ,
        message,
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: cb,
      });

    },

    onTotalsSwitchClick() {
      for(const invoice of this.invoices) {
        invoice._ext.totalChecked = this.totalsSwitchState
      }
      this.calculateDept()
    },

    onNextClick(event) {
      if(!this.debt.total) {
        return
      }

      this.confirm('Seguro que quieres finalizar el proceso?', event.currentTarget, () => {
        this.$store.dispatch(SAVE_SIMULATION, this.debt)
      })

    },

    onInterestClick(invoice) {
      this.calculateDept()
    },


    async loadInvoices() {
      if(this.claim._id) {
        let resp = await ApiService.get(`api/claim/${this.claim._id}/invoices`)
        let invoices = resp.data
        resp = await ApiService.get(`api/claim/${this.claim._id}/simulation`)
        this.simError = resp.error
        const sim_dict = listToDict(resp.data,'idInvoice')
        for(let invoice of invoices) {
          let sim = sim_dict[invoice['_id']] || { err: 'No se ha procesado esta factura'}
          invoice._ext = {
            sim,
            intChecked: true,
            totalChecked: false
          }
        }

        this.invoices = invoices
        this.calculateDept()


      }
    },



    onEditClick(invoice) {
      this.$router.push(`/invoice/${invoice._id}`)
    },

    async deleteInvoice(invoice) {
      const { _id } = invoice
      await ApiService.delete(`api/claim/${this.claim._id}/invoice/${_id}`)
      logInfo('Factura eliminada')
      await this.loadInvoices()
    },



  },
  
  async mounted() {
    this.debt = this.getDefaultDeptValues()
    await this.loadInvoices()
    setTimeout(()=> this.showTotalTooltip = true,1000)
  },



}
</script>
<style>

.claim-text {
  font-family: "reclamatec", serif;
  font-size: 14px;
  color: #757575;
  line-height: 1.325rem;
  font-weight: 300;
}


.claim-title {
  font-family: "reclamatec", serif;
  font-size: 19px;
  color: #333333;
  line-height: 2.625rem;
  font-weight: 600;
}

.invoice-totals {
  margin-top: 2px;
}

.invoices-list{
}

.invoice-parent {
  width: 550px;
}

.my-sim-error{
  padding: 5px 10px;
  background: #ffbcbc2e;
  color: indianred;
}

.total-divider{
  border-bottom: 3px double #ccc;

  /*box-shadow: 0 0 0 14px #ccc;*/
  /*outline: solid 8px #fff;*/
}


.sim-calculator-parent{

  border: 1px solid #006167;
  border-radius: 4px;

  /*background: #F7F7F7;*/

}

.my-small-input-switch {
  transform: scale(0.65);
}

.days-label{
  margin: 2px 10px;
}

.my-switch-parent {
  height: 20px;
}

.sim-currency {
  font-family: 'reclamatec', serif;

  font-size: 16px;
}

.invoice-total{
  margin: 2px 0
}
.sim-total-label-true {
  background: #fffbce73;
  padding: 0 4px;
}

.sim-total-label-false {
  background: #c0bfbf14;
  color: #b9b9b9;
  padding: 0 4px;
}

.vf-file-preview-filename {
  font-size: 13px;
  color: cadetblue;
  font-weight: bold;
  max-width: 400px;
}



.simtable thead th,
.simtable tfoot th {
  border: none !important;
}


table caption {
  padding: .5em 0;
}

table.dataTable th,
table.dataTable td {
  text-align: end;
  white-space: nowrap;
}

.simtable td {
  text-align: end;
}

.header-title{
  background: white;
  border: none !important;
}

.header-title{
  background: white;
  border: none !important;
}

.small-header th{
  height: 10px !important;
  padding: 0 !important;
  text-align: center;
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}

</style>


