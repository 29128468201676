<template>
  <div v-if="invoices" class="animate__animated animate__fadeIn">
    <div class="p-card mb-4" :key="`invoice_list_item_${k}`" v-for="(invoice,k) in orderInvoices(invoices)">
      <div class="invoice-header animate__animated animate__fadeIn animate__slow" >

        <ConfirmPopup></ConfirmPopup>

        <div class="d-flex justify-content-between">
          <div class="invoice-header-buttons">
            <Button :disabled="isEmpty(invoice)" icon="pi pi-file-edit" @click="(ev)=>onEdit(invoice)"  class="my-header-button my-button-primary mr-1 p-button-sm" severity="secondary" />
            <Button icon="pi pi-download" @click="onDownload(invoice)" class="my-header-button my-button-primary mr-1 p-button-sm" severity="secondary" />
          </div>


          <div class="d-flex full-width" :class="{'justify-content-between': showStatus, 'justify-content-end': !showStatus}">

            <Button v-if="!disableDelete" id="invoice-delete-btn" icon="pi pi-trash" @click="(ev)=>onDelete(invoice, ev)" class="my-header-button my-button-danger p-button-sm" severity="Danger" />

            <div v-if="showStatus">


              <tag v-if="isReview(invoice)" class="invoice-header-status" variant="success">
                completado
              </tag>

              <tag v-if="isCompleted(invoice)" class="invoice-header-status" variant="primary">
                pdte. revisión
              </tag>

              <tag v-if="isPending(invoice)" class="invoice-header-status" variant="white">
                en cola
              </tag>

              <tag v-if="isError(invoice)" class="invoice-header-status" variant="danger">
                <b-icon icon="exclamation-circle"></b-icon> error
              </tag>

              <tag v-if="isProcessing(invoice)" class="invoice-header-status" variant="yellow">
                <b-icon icon="gear" animation="spin" ></b-icon> procesando
              </tag>

              <tag v-if="isEmpty(invoice)" class="invoice-header-status" variant="danger">
                <b-icon icon="exclamation-circle"></b-icon> sin facturas
              </tag>

            </div>


          </div>



        </div>


      </div>

      <div class="invoice-body pr-1">


        <div class="d-flex invoice-stat-line">
          <div class="invoice-body-icon">
            <b-icon icon="calendar-date" class="stat-icon"></b-icon>
          </div>
          <div class="invoice-body-value pl-1">
            <span class="invoice-label" tabindex="0">{{formatDate(invoice.created_at)}}</span>
          </div>
        </div>

        <div class="d-flex invoice-stat-line">
          <div class="invoice-body-icon">
            <b-icon icon="file-earmark-pdf" class="stat-icon-pdf"></b-icon>
          </div>
          <div class="invoice-body-value pl-1" style="background: #f7f7f7">
            <span class="invoice-label font-weight-bold" tabindex="0">{{ellipsis(invoice.orig_file,125)}}</span>
          </div>
        </div>

        <div class="d-flex invoice-stat-line" v-if="showStatus">
          <span class="invoice-label mx-2"><b><u>Estado:</u></b></span>
          <div v-if="isPending(invoice)" class="status-warning invoice-label"><b-icon icon="info-circle" class="mr-1"></b-icon>En cola de extracción automática de campos</div>
          <div v-if="isError(invoice)" class="status-error invoice-label"><b-icon icon="exclamation-circle" class="mr-1"></b-icon>Error en la extracción automática de campos</div>
          <div v-if="isProcessing(invoice)" class="status-process invoice-label"><b-icon icon="info-circle" class="mr-1"></b-icon>Procesando extracción automática de campos....</div>
          <div v-if="isCompleted(invoice)" class="status-primary invoice-label"><b-icon icon="exclamation-circle" class="mr-1"></b-icon>Pendiente revisión: Revisa los campos extraídos </div>
          <div v-if="isEmpty(invoice)" class="status-error invoice-label"><b-icon icon="check-circle" class="mr-1"></b-icon>No se han encontrado facturas</div>
          <div v-if="isReview(invoice)" class="status-success invoice-label"><b-icon icon="check-circle" class="mr-1"></b-icon>Completado: Factura revisada por el usuario</div>

        </div>

        <slot name="footer" v-bind:invoice="invoice" v-bind:isFirst="k===0"></slot>


      </div>



    </div>


  </div>

</template>

<script>

import Button from 'primevue/button';
import {ellipsis} from "@/helpers";
import moment from 'moment'
import ConfirmPopup from "primevue/confirmpopup";
import ApiService from "@/services/api.service";
import _ from 'lodash'

export default {
  name: 'InvoiceList',

  props: ['invoices','showStatus','disableDelete'],

  components: {
    Button,
    ConfirmPopup
  },

  watch: {
    invoices(val){
    },

  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {
    ellipsis,

    onEdit(invoice) {
      this.$emit('onEdit', invoice)
    },

    onDelete(invoice, event) {
      if(this.disableDelete) {
        return
      }
      const self = this
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Seguro que quieres elimar esta factura?',
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.$emit('onDelete', invoice);
        },
      });
    },

    onDownload(invoice) {
      const { _id, orig_file } = invoice
      ApiService.download(`api/invoice/${_id}/download`,orig_file)

    },

    orderInvoices(invoices) {
      return _.orderBy(invoices,'created_at', 'desc')
    },

    formatDate(d) {
      moment.locale('es');
      return moment(d).format('LLLL');

    },

    isCompleted(invoice) {
      return invoice.status === 'COMPLETED' && invoice.invoices.length
    },

    isReview(invoice) {
      return invoice.status === 'CONFIRMED'
    },

    isEmpty(invoice){
      return invoice.status === 'COMPLETED' && !invoice.invoices.length
    },

    isPending(invoice) {
      return invoice.status === 'PENDING'
    },

    isError(invoice) {
      return invoice.status === 'ERROR'
    },

    isProcessing(invoice) {
      return invoice.status === 'PROCESSING'
    }


  },

  async mounted() {
  },

  beforeDestroy() {}
};
</script>

<style>

.invoice-header-buttons {
  width: 75px;
  /*border: 1px solid green;*/
}

.invoice-stat-line {
  margin-top: 5px;
}

.invoice-header-status {
  width: 100px;
}

.invoice-body-icon{
  /*border: 1px solid red;*/
  text-align: center;
  width: 35px;
}

.status-warning{
  font-weight: bold;
  color: indianred !important;
}


.status-process{
  font-weight: bold;
  color: indianred !important;
}

.status-error{
  font-weight: bold;
  color: red !important;
}

.status-primary{
  font-weight: bold;
  color: blue !important;
}

.status-success{
  font-weight: bold;
  color: green !important;
}

.invoice-label {
  font-family: Consolas, serif;
  font-size: 12px;
  color: #333333;
  /*font-weight: ;*/
}


.invoice-body {
  padding-bottom: 1px;
}

.invoice-body-value {
  border: 1px solid #e1e1e1;
  width: 100%;



}
.stat-icon {
  font-size: 15px;
}

.stat-icon-pdf {
  padding-top: 3px;
  font-size: 20px;
  color: indianred;
}

.invoice-header {
  padding: 5px 5px;
  border-bottom: 1px solid #eeeeee;
  background: #f3f3f3;
  /*background: #fbfbfb;*/
  color: #ffffff;
}
.invoice-header-title {
  /*border: 1px solid red;*/
  width: 320px;
}

.invoice-header-title span {
  font-size: 11px;
}


</style>